export interface IStatus {
  name: string;
  name_ru: string;
  value: number;
  is_active: boolean;
}

export interface IJobTitle {
  id: number;
  title_en: string;
  title_ru: string;
}

export interface IUser {
  allow_edit_permissions: boolean;
  id: number;
  employee_type: number;
  employee_type_at: string | null;
  name: string;
  surname: string;
  third_name: string | null;
  email: string;
  phone: string | null;
  timezone: number;
  dkk: number;
  telegram: string | null;
  date_of_born: string | null;
  avatar: string[] | null;
  award_types: IUserAwardType[] | null;
  spot: string | null;
  spot_id: number;
  spot_timezone: number;
  job_title: IJobTitle["title_ru"];
  job_title_id: IJobTitle["id"];
  job_title_en: string;
  job_title_up_to: null;
  job_title_down_to: null;
  snils: string;
  inn: string;
  fix_pay: number | null;
}

export interface IUserAwardType {
  award_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  user_employee_type_id: number;
  user_id: IUser["id"];
}

export interface IAward {
  id: string;
  user_id: IUser["id"];
  spot_id: number;
  section_status_id: number;
  plan_date: string | null;
  plan_gross: number | null;
  plan_net: number | null;
  fact_date: string | null;
  fact_gross: number | null;
  fact_net: number | null;
  approved_date: string | null;
  approved_gross: number | null;
  approved_net: number | null;
  paid_date: string | null;
  paid_gross: number | null;
  paid_net: number | null;
  taxes: number | null;

  //2024-08-26T22:54:45.000000Z - пример
  created_at: string;
  updated_at: string;
}

interface IDoubleAward {
  user_id: IUser["id"];
  spot_id: number;
  section_status_id: number;
  first_period_plan_date: string | null;
  first_period_plan_gross: number | null;
  first_period_plan_net: number | null;
  first_period_fact_date: string | null;
  first_period_fact_gross: number | null;
  first_period_fact_net: number | null;
  first_period_approved_date: string | null;
  first_period_approved_gross: number | null;
  first_period_approved_net: number | null;
  first_period_paid_date: string | null;
  first_period_paid_gross: number | null;
  first_period_paid_net: number | null;
  second_period_plan_date: string | null;
  second_period_plan_gross: number | null;
  second_period_plan_net: number | null;
  second_period_fact_date: string | null;
  second_period_fact_gross: number | null;
  second_period_fact_net: number | null;
  second_period_approved_date: string | null;
  second_period_approved_gross: number | null;
  second_period_approved_net: number | null;
  second_period_paid_date: string | null;
  second_period_paid_gross: number | null;
  second_period_paid_net: number | null;
  total_planned_gross: number | null;
  total_planned_net: number | null;
  total_fact_gross: number | null;
  total_fact_net: number | null;
  total_approved_gross: number | null;
  total_approved_net: number | null;
  total_paid_gross: number | null;
  total_paid_net: number | null;
}

export interface IShiftAward extends IDoubleAward {}

export interface IBonusAward extends IAward {}

export interface IContractStatus {
  name: string;
  name_ru: string;
  value: number;
}

export interface IContract {
  id: number;
  spot_id: number;
  user_id: IUser["id"];
  date: string;
  title: string;
  status: IContractStatus["value"];
  plan_award: number;
  fact_award: number;
  employee_comment: string | null;
  manager_comment: string | null;
  description: string | null;
  created_at: string;
  updated_at: string;
}

export interface IContractAward extends IAward {
  contract_id: IContract["id"];
  status: IStatus["value"];
}

export interface IBonusTableItem extends IBonusAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IContractTableItem extends IContractAward {
  contract: IContract["title"];
  contract_status: IContract["status"];
  user: string;
}

export interface IShiftTableItem extends IShiftAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IGeneralAward {
  first_period_approved_gross: number;
  first_period_approved_net: number;
  first_period_paid_gross: number;
  first_period_paid_net: number;
  first_period_plan_gross: number;
  first_period_plan_net: number;
  monthly_approved_gross: number;
  monthly_approved_net: number;
  monthly_paid_gross: number;
  monthly_paid_net: number;
  monthly_plan_gross: number;
  monthly_plan_net: number;
  second_period_approved_gross: number;
  second_period_approved_net: number;
  second_period_paid_gross: number;
  second_period_paid_net: number;
  second_period_plan_gross: number;
  second_period_plan_net: number;
  user_id: IUser["id"];
}

export interface IGeneralTableItem extends IGeneralAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IManagerAward extends IDoubleAward {
  id: string;
}
export interface IManagerTableItem extends IManagerAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface ITableItems<T> {
  barista: T[];
  helpers: T[];
  joe: T[];
}

export enum FundTabs {
  "Общая информация" = 0,
  "Вознаграждения за смены",
  "Контракты",
  "Премии ТБУ",
  "Премии",
  "Управляющий",
}

export enum FieldTypes {
  "plan" = "plan",
  "fact" = "fact",
  "approved" = "approved",
  "paid" = "paid",
}
